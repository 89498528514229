import { useRef, useEffect } from 'react';
import random from 'random';
import { gsap } from 'gsap';
import statements from './statements';
import styles from './styles.module.css';
import emailIcon from './email.png';
import linkedInIcon from './linkedin.png';

function App() {
  const scrollerRef = useRef();

  useEffect(() => {
    let priorPreviousStatementY = 0;
    let previousStatementY = 0;
    const interval = () => {
      clearInterval(interval);
      const getNextStatementY = () => {
        const numberOfStatements = statements.length;
        const statementHeight = scrollerRef.current.offsetHeight / numberOfStatements;
        const randomStatementY = random.int(0, numberOfStatements - 1) * statementHeight;
        if (randomStatementY === previousStatementY) return getNextStatementY();
        if (randomStatementY === priorPreviousStatementY) return getNextStatementY();
        priorPreviousStatementY = previousStatementY;
        previousStatementY = randomStatementY;
        return randomStatementY;
      }
      gsap.to(scrollerRef.current, { y: -Math.abs(getNextStatementY()) });
    };
    setInterval(interval, 3000);
  });

  const renderStatement = (statement) => (
    <div key={statement} className={styles.statementContainer}>
      {statement}
    </div>
  );

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div className={styles.nameContainer}>
          <div className={styles.nameText}>Dustin Boudreau</div>
          <div className={styles.titleText}>Senior Design Technologist @ Amazon</div>
        </div>
        <div className={styles.connectContainer}>
          <a href="https://www.linkedin.com/in/dustin-boudreau-7448248/" className={styles.connectIconContainer}>
            <img src={linkedInIcon} className={styles.connectIcon} alt="linked in icon" />
          </a>
          <a href="mailto:dustin.boudreau@gmail.com" className={styles.connectIconContainer}>
            <img src={emailIcon} className={styles.connectIcon} alt="email icon" />
          </a>
        </div>
      </div>
      <div className={styles.statementsWrapper}>
        <div className={styles.statementsContainer}>
          <div ref={scrollerRef} className={styles.statementsScrollerContainer}>
            {statements.map(renderStatement)}
          </div>
        </div>
      </div>
      <div className={styles.cta}>
        Let's chat, send me a message on <a href="https://www.linkedin.com/in/dustin-boudreau-7448248/">LinkedIn</a> or <a href="mailto:dustin.boudreau@gmail.com">email</a>.
      </div>
    </div>
  );
}

export default App;
