const statements = [
  'I make things',
  'I quickly build prototypes',
  'I design customer experiences',
  'I develop customer experiences',
  'I conceptualize products',
  'I advocate for the customer',
  'I solve problems',
  'I use instincts when data doesn\'t exist',
  'I will never stop learning'
];

export default statements;
